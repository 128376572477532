<template>
  <v-container
    fluid
    class="grey lighten-2"
  >
    <v-layout
      justify-center
      align-center
      class="mt-5 pt-5"
    >
      <v-flex
        xs10
        sm6
        md4
      >
        <v-card
          class="d-flex mt-5 flex-column justify-center align-center"
        >
          <v-avatar
            color="primary"
          >
            <v-icon
              color="white"
              large
            >
              mdi-truck
            </v-icon>
          </v-avatar>

          <h4
            class="text-center mt-4"
          >
            Пожалуйста, сохраните/перепишите себе логин и пароль и нажмите кнопку Авторизоваться
          </h4>
          <v-card-text>
            <v-form>
              <v-flex class="my-1">
                <v-text-field
                  v-model="user.name"
                  class="ma-2 pt-0"
                  label="Логин"
                  hide-details
                  outlined
                  dense
                  height="44"
                />
                <v-flex class="my-1">
                  <v-text-field
                    v-model="user.password"
                    class="ma-2 pt-0"
                    label="Пароль"
                    hide-details
                    outlined
                    dense
                    height="44"
                  />
                </v-flex>
                <v-layout
                  column
                  class="mt-1"
                >
                  <v-flex
                    xs2
                    class="d-flex justify-center"
                  >
                    <v-btn @click="$router.push({name: 'login'})">
                      Авторизоваться
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import auth from "../../auth.js";
import * as usersApi from "../../backend/usersApi.js";

export default {
  data: () => ({
    user: {},
    token: "",
  }),
  created(){
    auth.logout();
    this.token = this.$router.currentRoute.query.Token;
    auth.login(this.token);
    this.getInfo();
  },
  methods: {
    async getInfo() {
      this.user = await usersApi.getLoginInfo();
    },
  },
};
</script>
